<template>
  <div class="section content">
    <nav class="panel">
      <p class="panel-heading">My Apps</p>
      <div class="panel-block">
        <div class="columns is-multiline is-centered" style="width: 100%">
          <ebiz-apps-item v-for="(app, index) in apps" :key="index"
              :title="app.title"
              :label="app.label"
              :icon="app.icon"
              :href="app.href" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// @ts-expect-error: referencing hestia alias
import { ebizApps, ebizAppsItem } from "hestia";

export default defineComponent({
   name: 'myApps',
   components: {
     ebizAppsItem
   },
   data() {
    return {
      apps: []
    }
  },
   methods: {
    ...ebizApps
  },
  mounted() {
    this.apps = this.getUserApps();
  }
});
</script>