<template>
  <nav class="panel">
    <div class="panel-heading is-flex">
      <p>Recent Registrations</p>
      <div class="buttons" style="margin-left: auto">
        <button v-if="canCreateRegistrations" type="button" class="button is-info" @click="navigateToRegCreate()">
          <i class="fas fa-plus-circle"></i>
          <span class="pl-2">Request a new Registration</span>
        </button>
        <button
          type="button"
          class="button is-info"
          @click="navigateToRegSearch()"
        >
          <i class="fas fa-search"></i>
          <span class="pl-2">Registration Search</span>
        </button>
      </div>
    </div>
    <div class="panel-block">
      <div>
        <readOnlyGrid
          :gridColumns="gridColumns"
          :gridItems="gridItems"
          :isLoading="isLoading"
          :page-items-options="[5, 10, 20]"
        ></readOnlyGrid>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GridColumnProps } from "@progress/kendo-vue-grid";
// @ts-expect-error: import from hestia
import { readOnlyGrid, renderCellWithLink } from "hestia";
import { DesignRegService } from "@/services";
import { UserRole } from "@/enums";
import { hasRole } from "@/helpers";

export default defineComponent({
  name: "RecentRegistrationsWidget",
  components: {
    readOnlyGrid,
  },
  inject: ["tokenProvider"],
  data() {
    return {
      isLoading: false,
      gridItems: [] as any[],
      canCreateRegistrations: false,
      registrationBaseUrl: process.env.VUE_APP_DESIGN_REG_URL,
      gridColumns: [
        {
          field: "registrationNumber",
          title: "Registration",
          filter: "text",
          cell: renderCellWithLink(this.getRegistrationLink),
        },
        { field: "distributorName", title: "Distributor", filter: "text" },
        { field: "customerName", title: "Customer", filter: "text" },
        { field: "catalogPart", title: "Catalog Part", filter: "text" },
        { field: "registrationStatusName", title: "Status", filter: "text" },
        { field: "expirationDate", title: "Expiration Date", filter: "date", format: "{0:d-MMM-y}" },
        { field: "requesterDisplayName", title: "Requested By", filter: "text" },
        { field: "assignedToDisplayName", title: "Assigned To", filter: "text", hidden: !hasRole(UserRole.IsAllegro) },
      ] as GridColumnProps[],
      designRegService: new DesignRegService(this.tokenProvider),
    };
  },
  mounted() {
    this.loadRegistrationList();
  },
  methods: {
    async loadRegistrationList() {
      this.isLoading = true;
      try {
        const registrationList = await this.designRegService.getRecentRegistrationList();
        this.gridItems = registrationList.recentRegistrations;
        this.canCreateRegistrations = registrationList.canCreateRegistration;
      } catch (error) {
        (this as any).$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    getRegistrationLink(dataItem: any): string {
      return `${this.registrationBaseUrl}/registrations/details/${dataItem.registrationNumber}`;
    },
    navigateToRegCreate() {
      window.location.href = `${this.registrationBaseUrl}/registrations/create`;
    },
    navigateToRegSearch() {
      window.location.href = `${this.registrationBaseUrl}/search`;
    },
  },
});
</script>

<style></style>
