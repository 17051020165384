import { UserRole } from "@/enums";
import store from '@/store'

export function hasRole(role: UserRole | string): boolean {
    const userAuth = (store as any).state.auth.roles as string[];
    return userAuth && userAuth.includes(role);
}

export function getUserDisplayName(): string {
    return (store as any).state.auth.name as string;
}