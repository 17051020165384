import { Deserializable } from "@/interfaces";

export default class RecentRegistrationVM implements Deserializable<RecentRegistrationVM> {
  registrationId!: number;
  registrationNumber!: string;
  distributorName!: string;
  customerName!: string;
  catalogPart!: string;
  expirationDate!: Date;
  requesterDisplayName!: string;
  assignedToDisplayName!: string;
  registrationStatusName!: string;

  deserialize(model: RecentRegistrationVM): RecentRegistrationVM {
    if (model) {
      Object.assign(this, model);
      if (model.expirationDate) {
        this.expirationDate = new Date(model.expirationDate);
      }
    }

    return this;
  }
}
