//@ts-expect-error: referencing hestia alias
import { JsonClient } from "hestia";
import { Deserializer } from "@/helpers";
import TokenProviderService from "./token-provider-service";

export default class TypedApiClient {
  private apiClient!: any;
  protected baseUrl!: string;
  private tokenProvider: TokenProviderService;

  constructor(tokenProvider: TokenProviderService, baseUrl: string) {
    this.tokenProvider = tokenProvider;
    this.apiClient = new JsonClient(baseUrl);
  }

  async get<T>(path: string, ctorFunction: new () => T, id?: number | string, query?: string): Promise<T | T[]> {
    const response = await this.getRequest(path, id, query);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async post<T>(val: T, ctorFunction: new () => T, path?: string): Promise<T | T[]> {
    const response = await this.postRequest(val, path);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async put<T>(val: T, ctorFunction: new () => T, path?: string): Promise<T | T[]> {
    const response = await this.putRequest(val, path);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async getCache(path: string, id?: string | number): Promise<any> {
    await this.setToken();
    return this.apiClient.getCache(path, id);
  }

  async clearCache(path: string, id?: string | number): Promise<void> {
    await this.setToken();
    return this.apiClient.clearCache(path, id);
  }

  /** Sends GET request with given path, id and query. */
  private async getRequest(path: string, id?: string | number, query?: string) {
    await this.setToken();
    return this.apiClient.get(path, id, query);
  }

  /** Sends POST request with given val obj to specified path. */
  private async postRequest(val: any, path?: string) {
    await this.setToken();
    return this.apiClient.post(val, path);
  }

  /** Sends PUT request with given val object to specified path. */
  private async putRequest(val: any, path?: string) {
    await this.setToken();
    return this.apiClient.put(val, path);
  }

  /** Sets the auth token into the request header. */
  private async setToken() {
    const token = await this.tokenProvider.getToken();
    this.apiClient.setToken(token);
  }
}
