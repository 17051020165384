<template>
  <div>
    <navbar v-if="!viewOnly" :menu-items="[]"></navbar>
    <div class="container">
      <router-view v-slot="{ Component }">
        <transition name="fade-in-down" appear mode="out-in">
          <!-- View has to have only one root element so transition can be applied -->
          <div :key="$route.fullPath">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
      <loading-manager />
      <modal-error-wrapper></modal-error-wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { navbar } from "hestia";
import { TokenProviderService } from "./services";
import { ModalErrorWrapper } from "@/components";

export default {
  components: {
    navbar,
    ModalErrorWrapper,
  },
  provide() {
    return {
      tokenProvider: new TokenProviderService(this),
    };
  },
  computed: {
    viewOnly() {
      const routeMeta = this.$route.meta;
      return (!this.isAuthenticated && !routeMeta.allowAnonymous) || routeMeta.viewOnly;
    },
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
