import { RecentRegistrationListVM } from "@/models";
import TokenProviderService from "./token-provider-service";
import TypedApiClient from "./typed-api-client";

export default class DesignRegService extends TypedApiClient {
  constructor(tokenProvider: TokenProviderService) {
    super(tokenProvider, process.env.VUE_APP_DESIGN_REG_API_URL);
  }

  async getRecentRegistrationList(): Promise<RecentRegistrationListVM> {
    return await this.get<RecentRegistrationListVM>("Registrations/recent", RecentRegistrationListVM) as RecentRegistrationListVM;
  }
}