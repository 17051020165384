export default class TokenProviderService {
  private app: any;
  
  constructor(app: any) {
    this.app = app;
  }
  
  /** Gets the auth token. */
  async getToken() {
    return await this.app.$accessToken();
  }
}