import callback from '@/views/Callback.vue';
import { errorHandling } from 'hestia';
import { hasRole } from "@/helpers";
import { UserRole } from "@/enums";

export default [

    { path: '/', name: 'Home', component: () => import('@/views/Home.vue')},

    {
        path: '/callback', name: 'authCallback', component: callback,
        meta: { allowAnonymous: true, viewOnly: true }
    },
    {
        path: '/logout', name: 'Logout', component: () => import('@/views/Logout.vue'),
        meta: { allowAnonymous: true, viewOnly: true }
    },
    {
        path: '/error', name: 'Error', component: errorHandling.page,
        meta: { allowAnonymous: true },
        beforeEnter: (to, from, next) => {
          // interception before navigation to error page
          // add supportEmail and flag if user is from Allegro
          to.params.isAllegroUser = hasRole(UserRole.IsAllegro);
          to.params.supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
          next();
        }
    }
]