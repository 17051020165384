<template>
  <div>
    <modal-error :isAllegroUser="isAllegroUser" :supportEmail="supportEmail"></modal-error>
  </div>
</template>

<script lang="ts">

import { hasRole } from "@/helpers";
import { UserRole } from "@/enums";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalErrorWrapper",
  data() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    };
  },

  computed: {
    isAllegroUser() {
      return hasRole(UserRole.IsAllegro);
    },
  },
});
</script>

<style scoped></style>
