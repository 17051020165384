import { Deserializable } from "@/interfaces";

export default class Deserializer {
    /** Deserializes the response based on T provided.
     * If T implements Deserializable interface, then it uses its implementation.
     * Otherwise generic Object.assign({}) is used.
     * If provided response is Array of T, then returns Array of T deserialized.
     * @param model - any type to deserialize
     * @param ctorFunction - constructorFunction that can instantiate type T.
     * @returns deserialized T or deserialized T[]
     */
    static deserialize<T>(model: T, ctorFunction: (new () => T)): T | T[] {
        // in case of response is array 
        if (Array.isArray(model))
            return  model.map(e => Deserializer.deserializeType<T>(e, ctorFunction));
        
        return this.deserializeType<T>(model, ctorFunction);
    }

    private static deserializeType<T>(model: T, ctorFunction: (new () => T)): T {
        const deserializedType = new ctorFunction();
        // in case if response of deserializable type
        if (this.isDeserializable(deserializedType)) 
            return deserializedType.deserialize(model) as T;
        
        // fall back default deserialization logic
        Object.assign(deserializedType, model);
        return deserializedType;
    }

    /** Checks whether type implements Deserializable interface */
    private static isDeserializable<T>(object: any): object is Deserializable<T> {
        return object.deserialize !== undefined;
    }
}