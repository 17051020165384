import store from '@/store'

// https://github.com/okta/okta-auth-js#authorize-options
const config = {
  clientId: process.env.VUE_APP_IDP_CLIENT_ID,
  issuer: process.env.VUE_APP_IDP_AUTHORITY,
  redirectUri: `${process.env.VUE_APP_URL}/callback`,
  postLogoutRedirectUri: `${process.env.VUE_APP_URL}/logout`,
  roleClaimType: 'portal'
};


// same object, overrides what was passed in config
const tokenRequest = {
  scopes: [
    'openid',
    'profile',
    'portal',
    'dr'
  ]
}

export { auth } from 'hestia'
export const authConfig = {
  store,
  namespace: 'auth',
  config,
  tokenRequest
}