import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
import app from '@/main'


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const authGuard = (to) => {
    if (to.meta.allowAnonymous
        || store.getters['isAuthenticated']) return true;

    app.$signIn();
    return false;
}

const setTitle = (to) => {
    const suffix = process.env.VUE_APP_NAME;
    const prefix = to.meta.title ? to.meta.title + ' - ' : '';
    document.title = prefix + suffix;
}

router.beforeEach((to, from, next) => {
    if (!authGuard(to)) {
        next(false);
        return;
    }

    setTitle(to);
    next();
});
export default router